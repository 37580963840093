<template>
    <div id="pageList" class="ProvinceCityAreaList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="名称" headerAlign="left" align="left"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import ProvinceCityAreaCard from './ProvinceCityAreaCard';
    import {
        ref,
        reactive,
        toRefs,
        computed,
        defineComponent,
        onMounted,
        getCurrentInstance,
        watch,
        provide,
        nextTick
    } from 'vue';
    export default defineComponent ({
        name: "ProvinceCityAreaList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                pageList: {
                    canPage:false,
                    isShowFixCol:false,
                    isShowBtnGroup:false,
                    isShowQueryParam:false,
                    rowKey:'id',
                    modelMethod: "/provinceCityArea/pageData"
               }
            })
            onMounted(async ()=>{

            })
            const afterPageData=async (res1)=>{
                const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                let res = await utils.$$api.buildProvinceCityAreaTreeData({});
                let data=JSON.parse(res.treeData);
                dataObj.pageListRef.getTbInst().setTbData(data);
                // console.log(data);
                loading.close();
            }
            return{
                ...toRefs(dataObj),afterPageData
            }
        }
    });
</script>

<style scoped>
    .ProvinceCityAreaList{
        width: 100%;
    }
</style>